import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import AxiosInstance from "../utils/AxiosInstance";


const Summary = () => {
  const [resData, setResData] = useState<any>();
  const [startDate, setStatrDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const submitForm = async () => {
    var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    var beforeOneWeek2 = new Date(beforeOneWeek);
    const day = beforeOneWeek.getDay()
    const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
    const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
    const lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

    const data = {
      start_date: startDate ? startDate : lastMonday.toISOString().slice(0, 10),
      end_date: endDate ? endDate : lastSunday.toISOString().slice(0, 10),
    };
    setStatrDate(data.start_date);
    setEndDate(data.end_date);

    const res = await AxiosInstance.post("summary", data);
    if (res?.data?.status === 200) {
      setResData(res?.data?.data);
    }
  };

  useEffect(() => {
    submitForm();
  }, [])


  const addDays = (date: any, days: any) => {
    if (date) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString().slice(0, 10);
    }
  }


  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return (
    <div className="pcoded-wrapper">
      <div className="pcoded-content pt-0">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="row">
                <div className="col-md-12 col-xl-12">
                  <div className="card daily-sales p-3">
                    <label htmlFor="basic-url">
                      Please select Start and End date.
                    </label>
                    <div className="input-group pt-1">
                      <input
                        type="date"
                        className="form-control"
                        name="start_date"
                        value={startDate}
                        onChange={(e) => {
                          setStatrDate(e.target.value);
                        }}
                      />
                      <input
                        type="date"
                        className="form-control"
                        name="end_date"
                        min={addDays(startDate, 1)}
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      />
                      <div className="input-group-prepend">
                        <button
                          onClick={() => {
                            submitForm();
                          }}
                          className="btn btn-success"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6">
                  <div className="card daily-sales">
                    <Link to="/user" className="card-block">
                      <h6 className="mb-4">Total Orders</h6>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-600 text-c-yellow d-flex align-items-center m-b-0">
                            <i className="feather icon-users text-c-yellow f-30 m-r-10"></i>
                            {resData?.orderCount}
                          </h3>
                        </div>
                      </div>
                      <div
                        className="progress m-t-30"
                        style={{ height: "7px" }}
                      >
                        <div
                          className="progress-bar progress-c-theme"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow={50}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-6 col-xl-6">
                  <div className="card Monthly-sales">
                    <Link to="/merchant" className="card-block">
                      <h6 className="mb-4">Total Amount</h6>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-600 text-c-green d-flex align-items-center  m-b-0">
                            <i className="feather icon-award text-c-green f-30 m-r-10"></i>
                            $ {resData?.amountCount}
                          </h3>
                        </div>
                        <div className="col-3 text-right"></div>
                      </div>
                      <div
                        className="progress m-t-30"
                        style={{ height: "7px" }}
                      >
                        <div
                          className="progress-bar progress-c-theme"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow={35}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/*[ Recent Orders ] start */}
                <div className="col-xl-12 col-md-12">
                  <div className="card Recent-Users">
                    <div className="card-header">
                      <h5>Recent Orders</h5>
                    </div>
                    <div className="card-block px-0 py-3">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <th>Order Number</th>
                            <th>Price</th>
                            <th>Order Type</th>
                            <th>Status</th>
                            <th>Order At</th>
                          </thead>
                          <tbody>
                            {resData?.recentOrders?.map(
                              (order: any, index: any) => {
                                return (
                                  <tr className="unread">
                                    <td>
                                      <h6 className="mb-1">
                                        <Link to={`/order/detail/${order?.id}`}>
                                          {order.order_number}
                                        </Link>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6>
                                        <b>${order?.total_price}</b>
                                      </h6>
                                    </td>

                                    <td>
                                      <h6 className="mb-1">
                                        <span
                                          className={
                                            order.order_type == "eGift"
                                              ? "label label-eGift"
                                              : "label label-delivery"
                                          }
                                        >
                                          {" "}
                                          {order.order_type}{" "}
                                        </span>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-1">
                                        <span
                                          className={
                                            order?.status == "Accepted"
                                              ? "label label-success"
                                              : order?.status === "Delivered"
                                                ? "label label-info"
                                                : order?.status === "Pending"
                                                  ? "label label-dark"
                                                  : "label label-danger"
                                          }
                                        >
                                          {order.status}
                                        </span>
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-muted">
                                        {/* <i className="fas fa-circle text-c-green f-10 m-r-15"></i> */}
                                        {formatter
                                          .format(Date.parse(order.created_at))
                                          .toUpperCase()}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/*[ Recent Orders ] end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
